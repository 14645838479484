<template>
  <div class="h-full flex flex-col" style="background: #f2f2f2;">
    <div class="flex-1 flex items-center pt-0 pb-5 lg:pt-20 lg:pb-20">
      <div
        class="lg:container lg:mx-auto flex flex-col gap-y-0 lg:gap-y-4 w-full"
      >
        <cardSearch />
        <div
          class="bg-transparent lg:bg-white rounded border-0 lg:border border-solid border-borderInput flex flex-col lg:flex-row"
        >
          <side-nav-desktop
            :page="page"
            class="hidden lg:block"
          ></side-nav-desktop>
          <sideNavMobile
            :page="page"
            class="block lg:hidden"
            v-if="page === -1"
          ></sideNavMobile>
          <div class="w-full p-0 lg:p-5">
            <my-informations v-if="page === 1"></my-informations>
            <myAppointmets v-if="page === 2"></myAppointmets>
            <secure-account v-if="page === 3"></secure-account>
            <notifications-account v-if="page === 4"></notifications-account>
            <cin-download v-if="page === 5"></cin-download>
          </div>
        </div>
      </div>
    </div>
    <dokFooter />
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
const dokFooter = () => import("@/views/home/layouts/footer");
const sideNavDesktop = () => import("./layouts/navDesktop.vue");
const sideNavMobile = () => import("./layouts/navMobile.vue");

// Components
const myInformations = () => import("./components/MyInformations.vue");
const myAppointmets = () => import("./components/MyAppointment.vue");
const secureAccount = () => import("./components/secure.vue");
const notificationsAccount = () => import("./components/notifications.vue");
const cinDownload = () => import("./components/cinVerify.vue");
const cardSearch = () => import("@/views/home/layouts/cardSearch.vue");

export default {
  data() {
    return {
      page: 1
    };
  },
  mounted() {
    EventBus.$on("Change_Page_Number_My_Account_Patient", number => {
      this.page = number || 1;
    });
  },
  created() {
    console.log("HELLO FROM PATIENT SETTINGS");
  },
  components: {
    dokFooter,
    sideNavDesktop,
    sideNavMobile,
    myInformations,
    myAppointmets,
    secureAccount,
    notificationsAccount,
    cinDownload,
    cardSearch
  }
};
</script>
